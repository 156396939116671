<template>
  <section class="omikuji" id="app">
    <div class="omikuji__container">
      <div class="omikuji__contents">
        <div class="omikuji__title">
          <h2 class="title"><span class="red">WEB</span> おみくじ</h2>
          <p>
            <span class="br">占いたい運勢を選んで</span>おみくじを引いてください
          </p>
        </div>
        <div class="omikuji__box">
          <ul>
            <li class="reset modal-open" @click="clickOmikuji(1)">
              <img
                src="https://itabashi-en.jpn.org/wp/wp-content/themes/itabashi/images/omikuji01.png"
              />
            </li>
            <li class="exam modal-open" @click="clickOmikuji(2)">
              <img
                src="https://itabashi-en.jpn.org/wp/wp-content/themes/itabashi/images/omikuji02.png"
              />
            </li>
            <li class="marriage modal-open" @click="clickOmikuji(3)">
              <img
                src="https://itabashi-en.jpn.org/wp/wp-content/themes/itabashi/images/omikuji03.png"
              />
            </li>
            <li class="jobs modal-open" @click="clickOmikuji(4)">
              <img
                src="https://itabashi-en.jpn.org/wp/wp-content/themes/itabashi/images/omikuji04.png"
              />
            </li>
            <li class="fortune modal-open" @click="clickOmikuji(5)">
              <img
                src="https://itabashi-en.jpn.org/wp/wp-content/themes/itabashi/images/omikuji05.png"
              />
            </li>
            <li class="childcare modal-open" @click="clickOmikuji(6)">
              <img
                src="https://itabashi-en.jpn.org/wp/wp-content/themes/itabashi/images/omikuji06.png "
              />
            </li>
            <li class="business modal-open" @click="clickOmikuji(7)">
              <img
                src="https://itabashi-en.jpn.org/wp/wp-content/themes/itabashi/images/omikuji07.png"
              />
            </li>
            <li class="health modal-open" @click="clickOmikuji(8)">
              <img
                src="https://itabashi-en.jpn.org/wp/wp-content/themes/itabashi/images/omikuji08.png"
              />
            </li>
            <li class="love modal-open" @click="clickOmikuji(9)">
              <img
                src="https://itabashi-en.jpn.org/wp/wp-content/themes/itabashi/images/omikuji09.png"
              />
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div
      id="modalContent"
      :class="{ 'modal-container': true, active: modalActive }"
    >
      <div class="modal-body">
        <!-- 閉じるボタン -->
        <div class="modal-close" @click="modalClose">×</div>
        <!-- モーダル内のコンテンツ -->
        <div :class="['modal-content', resultClass]">
          <div class="modal__title">
            <h2>
              <img
                src="https://itabashi-en.jpn.org/wp/wp-content/themes/itabashi//images/logo_white.svg"
              />
            </h2>
            <p class="modal__category" v-if="result != null">
              {{ result[0]["type-txt"] }}縁起みくじ
            </p>
            <p class="fortune__name" v-if="result != null">
              {{ result[0]["name"] }}
            </p>
            <p class="fotune__subname" v-if="result != null">
              {{ result[0]["comment"] }}
            </p>
          </div>
          <div class="modal__text">
            <div class="modal__lead">
              <p class="advice">あなたへのアドバイス</p>
              <p class="advice_text" v-if="result != null">
                {{ result[0]["advice"] }}
              </p>
            </div>
            <div class="fortune__content">
              <ul v-if="result != null">
                <li v-if="result[0]['li_01'] != ''">
                  {{ result[0]["li_01"] }}
                </li>
                <li v-if="result[0]['li_02'] != ''">
                  {{ result[0]["li_02"] }}
                </li>
                <li v-if="result[0]['li_03'] != ''">
                  {{ result[0]["li_03"] }}
                </li>
                <li v-if="result[0]['li_04'] != ''">
                  {{ result[0]["li_04"] }}
                </li>
                <li v-if="result[0]['li_05'] != ''">
                  {{ result[0]["li_05"] }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
// import { parse } from 'papaparse';
// import csv from "@/assets/data.csv";
// import json from "@/assets/data.json";

export default {
  name: "App",

  data() {
    return {
      samples: null,
      result: null,
      modalActive: false,
    };
  },
  computed: {
    resultClass() {
      if (this.result != null) {
        return this.result[0]["type-name"];
      }

      return "blue";
    },
  },
  components: {
    // HelloWorld
  },
  mounted() {
    this.$el
      .querySelector("#modalContent")
      .addEventListener("click", this.closeModalByOutside);
  },
  beforeDestroy() {
    this.$el
      .querySelector("#modalContent")
      .removeEventListener("click", this.closeModalByOutside);
  },
  created: async function () {
    await fetch("./assets/data.json")
      .then((response) => {
        // console.log(response);
        return response.json();
      })
      .then((json) => {
        // console.log(json);
        this.samples = json;
      });
    // console.log(
    //   typeof this.samples,
    //   this.samples,
    //   this.samples.filter((sample) => sample["type-ID"] == 1)
    // );
  },
  methods: {
    clickOmikuji: function (num) {
      let rNum = Math.floor(Math.random() * 15) + 1;
      // console.log(num, rNum);
      let typeSample = this.samples.filter(
        (sample) => sample["type-ID"] == num
      );
      // console.log(typeSample);
      this.result = typeSample.filter((sample) => sample["result-ID"] == rNum);
      // console.log(this.result);
      this.modalActive = true;
    },
    modalClose: function () {
      this.modalActive = false;
    },
    closeModalByOutside(event) {
      console.log(
        "target",
        this.$el.querySelector("#modalContent"),
        event.target
      );
      if (this.$el.querySelector("#modalContent").isEqualNode(event.target)) {
        console.log("afafafafafa");
        this.modalActive = false;
      }
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
